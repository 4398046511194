// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-es-js": () => import("./../src/pages/platform-es.js" /* webpackChunkName: "component---src-pages-platform-es-js" */),
  "component---src-pages-platform-js": () => import("./../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-where-we-stand-js": () => import("./../src/pages/where-we-stand.js" /* webpackChunkName: "component---src-pages-where-we-stand-js" */)
}

